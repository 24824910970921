import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './context/UserContext';  // Adjust the path

const ProtectedRoutes = () => {
    const { user, loading } = useContext(UserContext);  // Access user and loading state

    if (loading) {
        return <p>Loading...</p>;  // Optionally show a loading indicator while fetching user info
    }

    if (!user) {
        // If the user is not authenticated, redirect to login
        return <Navigate to="/login" />;
    }

    // Render child routes (e.g., LandingPage, BrowseFlyers, etc.)
    return <Outlet />;
};

export default ProtectedRoutes;
