import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from './context/UserContext';
import './css/Login.css';

const validatePassword = require('../utils/validatePassword');

const ConfirmEmail = () => {
    const { token } = useParams();  // Get the token from the URL
    const [status, setStatus] = useState(null);  // Track success/failure of confirmation
    const [fullName, setFullName] = useState('');  // Full name input field
    const [password, setPassword] = useState('');  // Password input for new password
    const [passwordError, setPasswordError] = useState('');  // To handle password validation errors
    const [showPassword, setShowPassword] = useState(false);  // To toggle password visibility
    const navigate = useNavigate();  // For programmatic navigation after login

    // Access the setUser function from UserContext
    const { setUser } = useContext(UserContext);  // Use setUser to update the session


    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await fetch(`/api/auth/confirm-email/${token}`, {
                    method: 'GET'
                });
                const data = await response.json();

                if (data.success) {
                    setStatus('success');  // Set status to 'success' on successful confirmation
                } else {
                    setStatus('error');  // Set status to 'error' on failure
                }
            } catch (error) {
                setStatus('error');  // Handle network or unexpected errors
            }
        };

        confirmEmail();  // Call the API to confirm the email when the component mounts
    }, [token]);

    const handleSetDetails = async (e) => {
        e.preventDefault();

        // Validate password
        const passwordValidationError = validatePassword(password);
        if (passwordValidationError) {
            setPasswordError(passwordValidationError);
            return;
        }

        // Proceed only if fullName and password are entered and valid
        if (!fullName.trim()) {
            setPasswordError('Full name is required');
            return;
        }

        try {
            const response = await fetch('/api/auth/set-registration', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token, password, fullName })  // Send the token, password, and fullName
            });
            const data = await response.json();

            if (data.token) {
                // Automatically log the user in by setting the token
                localStorage.setItem('token', data.token);

                // Update the UserContext with the fullName and token
                setUser({
                    fullName: fullName,  // Set the full name in the session
                    email: data.email,  // Assuming the backend sends the email as well
                    avatar: data.avatar || 'https://via.placeholder.com/150',  // Default avatar
                });

                // Redirect to the home page after login
                navigate('/');
            } else {
                setPasswordError(data.message || 'Failed to set password');
            }
        } catch (error) {
            setPasswordError('An error occurred while setting the password. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <h1>Complete Registration</h1>

            {status === 'success' && (
                <div>
                    <p>Almost done!<br />Enter fullname and set a password to continue.</p>
                    <form onSubmit={handleSetDetails}>
                        Full Name
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Full Name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                required
                            />
                        </div>

                        Password
                        <div className="form-group password-container">
                            <input
                                type={showPassword ? "text" : "password"}  // Toggle between text and password
                                placeholder="Set your password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setPasswordError('');
                                }}
                                required
                            />
                            <span
                                className="toggle-password"
                                onClick={() => setShowPassword(!showPassword)}  // Toggle password visibility
                            >
                                <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                            </span>
                        </div>

                        {passwordError && <div className="error-message">{passwordError}</div>}
                        <button type="submit" className="login-btn">Continue</button>
                    </form>
                </div>
            )}

            {status === 'error' && (
                <div className="error-message">
                    <p>Email confirmation failed. The token may have expired or is invalid.</p>
                </div>
            )}

            {status === null && <p>Confirming your email, please wait...</p>}
        </div>
    );
};

export default ConfirmEmail;
