import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './css/HamburgerMenu.css';

const HamburgerMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="hamburger-container">
            <div className="hamburger-icon" onClick={toggleMenu}>
                <div className={menuOpen ? "bar open" : "bar"}></div>
                <div className={menuOpen ? "bar open" : "bar"}></div>
                <div className={menuOpen ? "bar open" : "bar"}></div>
            </div>

            {menuOpen && (
                <div className="menu-content">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/browse-flyers">Browse Flyers</Link></li>
                        <li><Link to="/create-meal-plan">Create Meal Plan</Link></li>
                        <li><Link to="/manage-shopping-lists">Manage Shopping Lists</Link></li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;
