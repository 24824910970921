import React from 'react';
import { Helmet } from 'react-helmet';

const ManageShoppingLists = () => {
    return (
        <div>
            <Helmet>
                <title>Manage Shopping Lists | Guuru</title>
            </Helmet>
            <h1>Manage Shopping Lists</h1>
            [COMING SOON]
        </div>
    );
};

export default ManageShoppingLists;
