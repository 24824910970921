import React from 'react';
import './css/Login.css';  // You can create this CSS file or import your existing CSS

const OAuthButtons = ({ handleOAuthLogin }) => {
    return (
        <div className="oauth-buttons">
            <h3>--------- OR ---------</h3>
            <button onClick={() => handleOAuthLogin('google')} className="oauth-btn google">Google</button>
            {
            //<button onClick={() => handleOAuthLogin('apple')} className="oauth-btn apple">Apple</button>
            //<button onClick={() => handleOAuthLogin('facebook')} className="oauth-btn facebook">Facebook</button>
            //<button onClick={() => handleOAuthLogin('microsoft')} className="oauth-btn microsoft">Microsoft</button>
            }
        </div>
    );
};

export default OAuthButtons;
