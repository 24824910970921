import React, { useContext, useState } from 'react';
import { UserContext } from './context/UserContext';  // Access user data from context
import { useNavigate } from 'react-router-dom';
import './css/Avatar.css';

const Avatar = () => {
    const { user, logout } = useContext(UserContext);  // Access user and logout function
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        logout();  // Call the logout function from context
        navigate('/login');  // Navigate to login after logging out
    };

    // Provide fallback values for avatar and full name
    const avatarSrc = user?.avatar || 'https://via.placeholder.com/150';  // Default avatar if null
    const fullName = user?.fullName || 'User Fullname';  // Default full name if null
    const email = user?.email || 'User Email';  // Default email if null

    return (
        <div className="avatar-container">
            {user && (
                <>
                    <img
                        src={avatarSrc}
                        alt="User Avatar"
                        className="avatar"
                        onClick={toggleDropdown}
                    />
                    {dropdownOpen && (
                        <div className="avatar-dropdown">
                            <p><strong>{fullName}</strong></p>
                            <p>{email}</p>
                            <button onClick={handleLogout} className="logout-btn">Logout</button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Avatar;
