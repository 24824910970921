import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import BrowseFlyers from './components/BrowseFlyers';
import CreateMealPlan from './components/CreateMealPlan';
import FlyerItems from './components/FlyerItems';
import ProtectedRoutes from './components/ProtectedRoutes';
import ManageShoppingLists from './components/ManageShoppingLists';
import Login from './components/Login';
import Signup from './components/Signup';
import ConfirmEmail from './components/ConfirmEmail';
import Logout from './components/Logout';
import OAuthSuccess from './components/OAuthSuccess';
import { UserProvider } from './components/context/UserContext';



import './App.css'; // Import central styles

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>

          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
          <Route path="/oauth-success" element={<OAuthSuccess />} />


          {/* Protect routes that require login */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/browse-flyers" element={<BrowseFlyers />} />
            <Route path="/flyers/:merchantId" element={<FlyerItems />} />
            <Route path="/create-meal-plan" element={<CreateMealPlan />} />
            <Route path="/manage-shopping-lists" element={<ManageShoppingLists />} />
          </Route>
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
