import React from 'react';
import { Helmet } from 'react-helmet';

const CreateMealPlan = () => {
    return (
        <div>
            <Helmet>
                <title>Create Meal Plan | Guuru</title>
            </Helmet>
            <h1>Create Meal Plan</h1>
            [COMING SOON]
        </div>
    );
};

export default CreateMealPlan;
