import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Remove token from localStorage (or wherever it's stored)
        localStorage.removeItem('token');

        // Redirect to login page
        navigate('/login');
    }, [navigate]);

    return null;  // No UI needed for this component
};

export default Logout;
