import React from 'react';
import HamburgerMenu from './HamburgerMenu';  // HamburgerMenu is nested in TopBar
import Avatar from './Avatar';  // Avatar is part of TopBar
import './css/TopBar.css';  // Import the CSS for the TopBar

const TopBar = () => {
    return (
        <header className="top-bar">
            <HamburgerMenu />  {/* Render the hamburger menu on the left */}
            <Avatar />  {/* No need to pass user as a prop */}
        </header>
    );
};

export default TopBar;
