import React, { createContext, useState, useEffect } from 'react';
const jwt_decode = require('jwt-decode');

// Create the UserContext
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);  // Add loading state

    // Helper function to check if the token is still valid
    const isTokenValid = (token) => {
        try {
            const decodedToken = jwt_decode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp > currentTime;  // Check if the token has expired
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        // Check if the token exists in localStorage
        const token = localStorage.getItem('token');
        if (token && isTokenValid(token)) {
            // Fetch user details based on token
            fetch('/api/auth/me', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.fullName) {
                    setUser({
                        fullName: data.fullName,
                        email: data.email,
                        avatar: data.avatar || 'https://via.placeholder.com/150',
                    });
                } else {
                    console.error('Failed to fetch user details:', data);
                }
                setLoading(false);  // Done fetching
            })
            .catch(err => {
                console.error('Error fetching user data:', err);
                setLoading(false);  // Also mark as done in case of an error
            });
        } else {
            setLoading(false);  // No token, nothing to fetch, set loading to false
        }
    }, []);

    // Log out function to clear the token and reset user
    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);  // Clear user from context
    };

    return (
        <UserContext.Provider value={{ user, setUser, logout, loading }}>
            {children}
        </UserContext.Provider>
    );
};
