import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OAuthSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            localStorage.setItem('token', token);  // Save token in localStorage
            navigate('/');  // Redirect to home page
        }
    }, [navigate]);

    return <div>Logging you in...</div>;
};

export default OAuthSuccess;