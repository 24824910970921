import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './css/FlyerItems.css';

const FlyerItems = () => {
    const { merchantId } = useParams();
    const [items, setItems] = useState([]);
    const [merchant, setMerchant] = useState({ name: '', logoUrl: '' });
    const [validity, setValidity] = useState({ validFrom: null, validTo: null });
    const [loading, setLoading] = useState(true); // Added loading state

    useEffect(() => {
        // Fetch items from API
        fetch(`/api/flyers/items/${merchantId}`)
            .then((res) => res.json())
            .then((data) => {
                setItems(data || []);
                // Set validity dates from the first item
                if (data.length > 0) {
                    setValidity({ validFrom: data[0].validFrom, validTo: data[0].validTo });
                } else {
                    setValidity({ validFrom: null, validTo: null }); // Handle no validity case
                }
                setLoading(false); // Stop loading when done
            })
            .catch((error) => {
                console.error('Error fetching items:', error);
                setLoading(false); // Stop loading on error
            });

        // Fetch merchant details separately
        fetch(`/api/flyers/merchants/${merchantId}`)
            .then((res) => res.json())
            .then((data) => {
                setMerchant({ name: data.merchantName, logoUrl: data.logoUrl });
            })
            .catch((error) => console.error('Error fetching merchant details:', error));            
    }, [merchantId]);

    const renderPriceInfo = (item) => {
        let priceInfo = '';
        if (item.itemPrePriceText) priceInfo += `${item.itemPrePriceText} `;
        if (item.itemPrice !== null) priceInfo += `$${item.itemPrice}`;
        if (item.itemDiscount > 0) priceInfo += ` (${item.itemDiscount}% off)`;
        if (item.itemPostPriceText) priceInfo += ` ${item.itemPostPriceText}`;
        if (item.itemSaleStory) priceInfo += ` - ${item.itemSaleStory}`;
        return priceInfo;
    };

    // Group items by category
    const groupedItems = items.reduce((groups, item) => {
        const category = item.itemCategory || 'Uncategorized';
        if (!groups[category]) groups[category] = [];
        groups[category].push(item);
        return groups;
    }, {});

    if (loading) {
        return <div>Loading...</div>; // Display loading state while fetching data
    }

    return (
        <div className="items-container">
            <h1>
                <img src={`/api/flyers/proxy-image?url=${encodeURIComponent(merchant.logoUrl)}`} alt={`${merchant.name} logo`} />
                {merchant.name}
            </h1>
            {validity.validFrom && validity.validTo && (
                <p>Valid from: {new Date(validity.validFrom).toLocaleDateString()} to {new Date(validity.validTo).toLocaleDateString()}</p>
            )}

            {/* Display a message if no items are found */}
            {items.length === 0 ? (
                <p>No flyer items found for this merchant.</p>
            ) : (
                <>
                    {/* Horizontal menu with category anchor links */}
                    <nav className="category-nav">
                        {Object.keys(groupedItems).map((category) => (
                            <a key={category} href={`#category-${category}`} className="category-link">
                                {category}
                            </a>
                        ))}
                    </nav>

                    {/* Render items grouped by category */}
                    {Object.keys(groupedItems).map((category) => (
                        <div key={category} id={`category-${category}`} className="category-section">
                            <h2>{category}</h2>
                            <table className="items-table">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupedItems[category].map((item) => (
                                        <tr key={item.itemId}>
                                            <td>
                                                <img
                                                    src={`/api/flyers/proxy-image?url=${encodeURIComponent(item.itemImageUrl)}`}
                                                    alt={item.itemName}
                                                    className="thumbnail"
                                                />
                                            </td>
                                            <td>{item.itemName}<br /><small>{item.itemBrand}</small></td>
                                            <td>{renderPriceInfo(item)}</td>
                                            <td>[{item.itemId} | {item.merchantName}]</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </>
            )}

            <div className="back-to-main">
                <Link to="/browse-flyers" className="back-link">Back to Flyers</Link>
            </div>
        </div>
    );
};

export default FlyerItems;
