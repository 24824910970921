import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './context/UserContext';  // Import the UserContext
import OAuthButtons from './OAuthButtons';  // Import the new component
import './css/Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');  // Use setError to display errors if necessary
    const { setUser } = useContext(UserContext);  // Get setUser from UserContext
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();  // Prevent the form from submitting and reloading the page

        try {
            const response = await fetch('/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                // Store the token in localStorage
                localStorage.setItem('token', data.token);  // Save token to localStorage

                // Fetch user info immediately after login
                const userResponse = await fetch('/api/auth/me', {
                    headers: { 'Authorization': `Bearer ${data.token}` }
                });

                const userData = await userResponse.json();

                // Update the UserContext with the fetched user data
                setUser({
                    fullName: userData.fullName,
                    email: userData.email,
                    avatar: userData.avatar || 'https://via.placeholder.com/150',
                });

                // Redirect to the dashboard or home page
                navigate('/');
            } else {
                setError(data.message || 'Login failed.');  // Handle errors
            }
        } catch (error) {
            setError('Login failed. Please try again.');
            console.error('Login failed:', error);
        }
    };

    const handleOAuthLogin = (provider) => {
        // Redirect to backend OAuth route for the provider
        window.location.href = `/api/auth/oauth/${provider}`;
    };

    return (
        <div className="login-container">
            <h1>Welcome back</h1>
            <form className="login-form" onSubmit={handleLogin}>
                {error && <div className="error-message">{error}</div>}
                <div className="form-group">
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Email'
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Password'
                        required
                    />
                </div>
                <button type="submit" className="login-btn">Login</button>

                <div className="signup-section">
                    <p>Don't have an account? <a href="/signup">Sign up here</a></p>
                </div>
            </form>

            <OAuthButtons handleOAuthLogin={handleOAuthLogin} />

        </div>
    );
};

export default Login;
