import React, { useState } from 'react';
import OAuthButtons from './OAuthButtons';  // Import the new component
import './css/Login.css';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState('');  // For success message
    const [error, setError] = useState('');  // To store any error messages
    const [formDisabled, setFormDisabled] = useState(false);  // To disable the form after successful registration

     const handleSignup = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/auth/signup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            if (data.success) {
                setSuccess((
                    <>
                        Registration successful!<br />
                        A confirmation email has been sent.
                    </>
                ));
                setError('');  // Clear any error messages
                setFormDisabled(true);  // Disable the form
            } else {
                setError(data.message || 'Registration failed');
            }
        } catch (error) {
            setError('An error occurred during signup.<br/>Please try again.');
        }
    };

    const handleOAuthLogin = (provider) => {
        // Redirect to backend OAuth route for the provider
        window.location.href = `/api/auth/oauth/${provider}`;
    };

    return (
        <div className="login-container">
            <h1>Create an account</h1>

            {/* Show success message and disable form on success */}
            {success && <div className="success-message">{success}</div>}
            {!formDisabled && (
                <form onSubmit={handleSignup}>
                    {error && <div className="error-message">{error}</div>}

                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                        />
                    </div>

                    <button type="submit" class="login-btn">Register</button>
                </form>
            )}

            <div className="signup-section">
                <p>Already have an account? <a href="/login">Login now</a></p>
            </div>

            <OAuthButtons handleOAuthLogin={handleOAuthLogin} />
        </div>
    );
};

export default Signup;
